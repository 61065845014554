import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
//import ContactForm from '@widgets/ContactForm'
//import ContactInfo from '@widgets/ContactInfo'
//import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Contacto' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Contáctanos"
          subheader=<div>Si deseas contactarnos por favor escríbenos a <a href="mailto:webmaster@nuptia.es" style={{color:'black'}}>webmaster@nuptia.es</a>.</div>
        />
{/*
        <Divider />
        <ContactForm />
*/}
      </Main>
      <Sidebar>
{/*
        <Commitment />
        <Divider />
        <ContactInfo />
*/}
      </Sidebar>
    </Stack>
  </>
)
